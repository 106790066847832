import React, { RefObject, useEffect, useRef } from 'react';

import Arrow from '@assets/svg/arrow.svg';
import Baby from '@assets/svg/baby.svg';
import Child from '@assets/svg/child.svg';
import Close from '@assets/svg/close-configurator.svg';
import Dot from '@assets/svg/dot.svg';
import NewBorn from '@assets/svg/new-born.svg';

import s from './CustomSelect.module.scss';

const iconDictionary: {[key: number]: object } = {
  0: <NewBorn className={s.customSelect__iconSvg} />,
  1: <Baby className={s.customSelect__iconSvg} />,
  2: <Child className={s.customSelect__iconSvg} />,
};

interface ICustomSelect {
  text: string;
  placeholder: string;
  range: string[];
  visible: boolean;

  handleClick(visibility: boolean, fieldIndex?: number): void;

  ageSelector?: boolean;

  handleSelect(optionIndex?: string, fieldIndex?: number, index?: number): void;

  value: any;
  isGuaranteeForm?: boolean;
  required?: boolean;
  fieldIndex?: number;

  reference?: RefObject<HTMLDivElement>;
  isValueWrong?: boolean;
}

export const CustomSelect = ({
                               text,
                               placeholder,
                               range,
                               visible,
                               handleClick,
                               ageSelector,
                               handleSelect,
                               value,
                               isGuaranteeForm,
                               required,
                               fieldIndex,
                               reference,
                               isValueWrong,
                             }: ICustomSelect) => {

  const refCustomSelect = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  
  const serviceElementFocus = (borderColor: string) => {
    if (refCustomSelect.current) {
      refCustomSelect.current.style.borderColor = borderColor;
    }
  };

  useEffect(() => {
    const addFocusToElement = () => serviceElementFocus('black');
    const addFocusOutToElement = () => serviceElementFocus('white');

    refInput.current?.addEventListener('focus', addFocusToElement);
    refInput.current?.addEventListener('blur', addFocusOutToElement);

    return () => {
      refInput.current?.removeEventListener('focus', addFocusToElement);
      refInput.current?.removeEventListener('blur', addFocusOutToElement);
    };
  }, [refInput.current]);

  function renderFieldText() {
    return value ?? placeholder;
  }

  function renderGuaranteeFieldText() {
    return (
      <>
        <p className={`${s.customSelect__inputLabel} ${value ? s.full : ''}`}>{placeholder}</p>
        <input className={`${s.customSelect__input} ${value ? s.full : ''} ${isValueWrong ? s.red : ''}`}
               ref={refInput}
               onKeyPress={(e) => {
                 e.preventDefault();
               }}
               value={value} placeholder={placeholder} required={required} onChange={() => {
        }} />
      </>
    );
  }

  function renderGuaranteeFiledIcon() {
    return (
      <div className={`${s.customSelect__arrow} ${value ? s.selected : ''}`}>
        <Arrow className={s.customSelect__arrowSvg} />
      </div>
    );
  }

  function renderFiledIcon() {
    return (value ?
        <div className={`${s.customSelect__arrow} ${s.customSelect__close}`}>
            <Close className={s.customSelect__closeSvg}  onClick={isGuaranteeForm ?
              () => handleSelect(undefined, fieldIndex) :
              () => handleSelect(undefined)}/>
        </div> :
        <div className={s.customSelect__arrow} >
          <Arrow className={s.customSelect__arrowSvg} />
        </div>
    );
  }

  function renderOptions() {
    return (
      range.map((option, index) => {
        return (
          <li className={s.customSelect__option} key={index}
              onClick={isGuaranteeForm ? () => handleSelect(option, fieldIndex, index) : () => handleSelect(option)}>
            <p>{option}</p>
            <div className={`${s.customSelect__iconRing} ${option === value ? s.selected : ''}`}>
              <div className={`${s.customSelect__icon} ${option === value ? 'selected' : ''}`}>
                {ageSelector ? iconDictionary[index] : <Dot className={s.customSelect__iconSvg} />}
              </div>
            </div>
          </li>
        );
      })
    );
  }

  return (
    <div className={`${s.customSelect} ${isGuaranteeForm ? s.guarantee : ''}`}>
      {!isGuaranteeForm && <p className={s.customSelect__text}>{text}</p>}
      <div className={`${s.customSelect__field} ${isGuaranteeForm ? s.guarantee : ''} ${isValueWrong ? s.red : ''}`}
           ref={reference ? reference : refCustomSelect}
           onClick={isGuaranteeForm ?
             () => handleClick(!visible, fieldIndex) : 
             () => handleClick(!visible)}>
        {isGuaranteeForm ? renderGuaranteeFieldText() : renderFieldText()}
        {isGuaranteeForm ? renderGuaranteeFiledIcon() : renderFiledIcon()}
      </div>
      <div className={`${s.customSelect__dropdown} ${visible ? s.visible : ''} ${isGuaranteeForm ? s.guarantee : ''}`}>
        {renderOptions()}
      </div>
    </div>
  );
};
