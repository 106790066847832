import React, { FC, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

import Signet from '@assets/svg/sygnet.svg';

import s from './Loader.module.scss';


interface ILoader {
  isLoading: boolean;
}

export const Loader: FC<ILoader> = ({ isLoading }) => {
  const refSignet = useRef<HTMLDivElement>(null);
  const refLoaderWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tl = gsap.timeline();
    if (isLoading && refSignet.current !== null && refLoaderWrapper.current !== null) {
      tl
        .set(refLoaderWrapper.current, {
          autoAlpha: 0,
        })
        .to(refLoaderWrapper.current, {
          autoAlpha: 1,
          duration: .3,
        })
        .to(refSignet.current.children[0], {
          rotation: '360',
          repeat: -1,
          duration: 2,
          ease: 'linear',
        }, '<');
    }
    return () => {
      tl.kill();
    };
  }, [isLoading]);


  return (
    isLoading
      ? <div className={s.loader__wrapper} ref={refLoaderWrapper}>
        <div className={s.loader} ref={refSignet}>
          <Signet className={s.loaderSvg} />
        </div>
      </div>
      : <></>
  );
};
