import React, { FC, useEffect, useRef } from 'react';

import { gsap, ScrollTrigger } from 'gsap/all';

import { SearchEngine } from '../search-engine/SearchEngine.component';
import { Pin } from '../pin/Pin.component';

import Map from '../../assets/images/world-map.jpg';

import s from './StoreSearch.module.scss';

interface IStoreSearch {
  header: string;
  fieldText: string;
  shopsURL: string;
  currentLocale: string;
  isBackgroundVisible?: boolean;
}

export const StoreSearch: FC<IStoreSearch> = ({ header, fieldText, shopsURL, currentLocale, isBackgroundVisible }) => {

  const masterPin = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    if (masterPin.current) {
      gsap.set(masterPin.current.children, {
        y: -80,
      });
      gsap.to(masterPin.current.children, {
        duration: 1,
        y: 0,
        ease: 'bounce.out',
        scrollTrigger: {
          start: 'center-=50px bottom',
          trigger: '#store-search',
          scrub: false,
        },
      });
    }
  }, []);

  const darkness = () => {
    if (masterPin.current) {
      gsap.to(masterPin.current.children, {
        scale: 1,
        y: 50,
        ease: 'bounce.out',
      });
    }
  };

  const lightness = () => {
    if (masterPin.current) {
      gsap.to(masterPin.current.children, {
        scale: 1,
        y: 0,
        ease: 'ease',
      });
    }
  };

  return (
    <section className={s.storeSearch} id={'store-search'}>
      {
        isBackgroundVisible &&
        <div className={s.storeSearch__map}>
          <img src={Map} className={s.storeSearch__background} alt={''} />
        </div>
      }
      <div className={s.storeSearch__wrapper}>
        <div className={s.storeSearch__content}>
          <div className={s.storeSearch__pin} ref={masterPin}>
            <Pin className={s.storeSearch__pinSvg} />
          </div>
          <h2 className={s.storeSearch__header}>{header}</h2>
          <div className={s.storeSearch__search}
               onMouseOver={() => darkness()}
               onMouseLeave={() => lightness()}
          >
            <SearchEngine
              higher
              isCalledFromProductList
              filedText={fieldText}
              locale={currentLocale}
              shopsURL={shopsURL}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
