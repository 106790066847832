import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import { convertStringToBoolean } from '../../app/product/product.helper';

import { AllWpProduct, FilterData, ListedProductEdge } from 'types';
import { PageType } from '@constants/pageType.constants';

import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import { Configurator } from '../configurator/Configurator.component';
import Product from '../product/Product.component';
import Tag from '../product/Tag.component';

import s from './Products.module.scss';
import { replaceNbsp } from '@helpers/translation.helper';

interface IProducts {
  header: string | ReactNode;
  description: string;
  noProductsFoundText: string;
  dataToFilter: FilterData | null;
  allWpProduct: AllWpProduct;
  isTopBarVisible: boolean
}

const Products: FC<IProducts> = ({ header, description,noProductsFoundText, dataToFilter, allWpProduct, isTopBarVisible }) => {
  const [wpProducts, setWpProducts] = useState<AllWpProduct>(allWpProduct);
  const [isProductsListEmpty, setIsProductsListEmpty] = useState<boolean>(false);
  const intl = useIntl();

  const tagTranslation: string[] = [
    intl.formatMessage({ id: 'tag__0' }),
    intl.formatMessage({ id: 'tag__1' }),
    intl.formatMessage({ id: 'tag__2' }),
  ];

  const filterProducts = (age: number | null, weight: number | null, growth: number | null) => {
    const filteredProducts: AllWpProduct = { edges: [] };
    filteredProducts.edges = [...allWpProduct.edges];

    filteredProducts.edges = filteredProducts.edges.filter((edge) => {
      let isAgeOk: boolean = false;
      let isWeightOk: boolean = false;
      let isGrowthOk: boolean = false;

      if (age === null) {
        isAgeOk = true;
      } else {
        edge.node.productMetaData.categoryage.forEach((category) => {
          if (parseInt(category,10) === age) {
            isAgeOk = true;
          }
        });
      }

      if (weight === null) {
        isWeightOk = true;
      } else {
        edge.node.productMetaData.categoryweight.forEach((category) => {
          if (parseInt(category,10) === weight) {
            isWeightOk = true;
          }
        });
      }

      if (growth === null) {
        isGrowthOk = true;
      } else {
        edge.node.productMetaData.categoryheight.forEach((category) => {
          if (parseInt(category,10) === growth) {
            isGrowthOk = true;
          }
        });
      }

      return isAgeOk && isWeightOk && isGrowthOk;
    });

    (filteredProducts.edges.length === 0) ? setIsProductsListEmpty(true) : setIsProductsListEmpty(false);
    setWpProducts(filteredProducts);
  };

  const prepareProductList = () => {
    const productList = wpProducts.edges.map((product: ListedProductEdge, index) => {
      
      const isCompatibleWithIQBase = product.node.productMetaData.iscompatiblewithiqbase;
      const isCompatibleWithIQBase20C = product.node.productMetaData.iscompatiblewithiqbase20c;
      const isCloudCare = product.node.productMetaData.iscloudcare;
      const isProductIncludedInNewStyleCampaign = product.node.productMetaData.newcolorversionavailable;
      
      if(convertStringToBoolean[product.node.productMetaData.isproductvisible]) {
        return (
          <Product
            name={product.node.productMetaData.productname}
            colors={product.node.productData.productavailablecolors}
            colorVisible
            pageType={PageType.CAR_SEAT}
            slug={product.node.slug}
            thumbnail={product.node.productMetaData.thumbnail}
            key={index}
            isCompatibleWithIQBase={isCompatibleWithIQBase}
            isCompatibleWithIQBase20C={isCompatibleWithIQBase20C}
            isCloudCare={isCloudCare}
            isProductIncludedInNewStyleCampaign={isProductIncludedInNewStyleCampaign}
            thumbnailPhotos={product.node.productData.thumbnailphotos}
            avionautShopUrl={product.node.productData.avionautShopUrl}
          >
            {product.node.productData.tags && product.node.productData.tags.map((tag) => {
              return (
                <Tag key={tag} value={tagTranslation[parseInt(tag,10)]} />
              );
            })}
            {product.node.productData.producttags && product.node.productData.producttags.map((tag) => {
              return (
                <Tag key={tag.tagname} value={tag.tagname} />
              );
            })}
          </Product>
        );
      }
    });

    return productList;
  };

  useEffect(() => {
    if (typeof dataToFilter !== 'undefined' && dataToFilter !== null) {
      const { age, weight, growth } = dataToFilter;
      filterProducts(age, weight, growth);
    }
  }, [dataToFilter]);

  return (
    <div className={s.products}>
      <Container>
        <Configurator isHeaderHide isTopBarVisible={isTopBarVisible} filterProducts={filterProducts} preselectedData={dataToFilter} />
        <div className={s.products__header}>
          <Header isMainHeader>{header}</Header>
        </div>
        <div className={s.products__description}>
          <p className={s.products__text}>{replaceNbsp(description)}</p>
        </div>
        {
          isProductsListEmpty
            ? <div className={s.products__info}>{noProductsFoundText}</div>
            : ''
        }
        <div className={s.products__list}>
          {
            prepareProductList()
          }
        </div>
      </Container>
    </div>
  );
};

export default Products;
