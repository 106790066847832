import React from 'react';

interface IPin {
  [key: string]: string | number | undefined;

  width?: number;
  height?: number;
}

export const Pin = (props: IPin) => {
  const {
    width = 73,
    height = 95,
    ...rest
  } = props;

  return (
    <svg width={width} height={height} {...rest} viewBox="0 0 73 95" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M36.5478 0C16.3604 0 0 16.245 0 36.29C0 58.805 18.3696 77.805 32.7208 93.385C34.73 95.57 38.27 95.57 40.2792 93.385C54.6304 77.805 73 58.9 73 36.29C73 16.245 56.7353 0 36.5478 0ZM36.5478 19.665C45.8283 19.665 53.291 27.075 53.291 36.29C53.291 45.505 45.8283 52.915 36.5478 52.915C27.2674 52.915 19.8047 45.505 19.8047 36.29C19.8047 27.075 27.2674 19.665 36.5478 19.665Z"
            fill="white" />
    </svg>
  );
};
