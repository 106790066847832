import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

import { AllWpProduct, AllWpTopBar, FilterData } from '../../types';
import { getLink } from '@helpers/linking.helper';
import { convertStringToBoolean } from '../../app/product/product.helper';

import { PageType } from '@constants/pageType.constants';

import Products from '@components/products/Products.component';
import Thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg';
import { StoreSearch } from '@components/store-search/StoreSearch.component';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

interface IProductsTemplate {
  location: {
    state: {
      key: string,
      filterData?: FilterData;
    }
  },
  pageContext: {
    localePath: string
  }
  data: {
    allWpProduct: AllWpProduct;
    allWpTopBar: AllWpTopBar;
  }
}

const ProductsTemplate: FC<IProductsTemplate> = (props) => {
  const intl = useIntl();

  const {
    location: {
      state,
    },
    data: {
      allWpProduct,
      allWpTopBar,
    },
  } = props;
  
  const isTopBarVisible = allWpTopBar.edges[0].node && convertStringToBoolean[allWpTopBar.edges[0].node.topBarData.istopbarvisible]

  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        htmlAttributes={{
          language: intl.locale
        }}
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.CAR_SEATS)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.CAR_SEATS)}/`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.CAR_SEATS)}/`}
        title={`${intl.locale === 'pl' || intl.locale === 'de' ? '': 'Avionaut - '}${intl.formatMessage({ id: 'metaTitle__carSeats' })}`}
        description={intl.formatMessage({ id: 'metaDescription__carSeats' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'metaTitle__carSeats' }),
          },
          {
            property: 'og:description',
            content: intl.formatMessage({ id: 'metaDescription__carSeats' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__carSeats' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__carSeats' }),
          },
        ]}
      />
      <Products
        header={intl.formatMessage({ id: 'products__productsList' })}
        description={intl.formatMessage({ id: 'products__productsListDescription' })}
        noProductsFoundText={intl.formatMessage({ id: 'products__noProductsFound' })}
        dataToFilter={state ? (state.filterData || null) : null}
        allWpProduct={allWpProduct}
        isTopBarVisible={isTopBarVisible}
      />
      <StoreSearch
        header={intl.formatMessage({ id: 'products__storeSearch' })}
        fieldText={intl.formatMessage({ id: 'storeSearch__placeholder' })}
        shopsURL={getLink(intl.locale, PageType.SHOPS)}
        currentLocale={intl.locale}
        isBackgroundVisible
      />
    </>
  );
};

export const query = graphql`
  query GetProductsList($locale: String) {
    allWpProduct(
      sort: {fields: date, order: DESC}
      filter: {locale: {locale: {eq: $locale}}}
    ) {
      edges {
        node {
          locale {
            locale
          }
          productMetaData {
            newcolorversionavailable
            iscompatiblewithiqbase
            iscompatiblewithiqbase20c
            iscloudcare
            isproductvisible
            categoryage
            categoryheight
            categoryweight
            productname
            thumbnail {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
          productData {
            productavailablecolors {
              ... on WpColor {
                id
                colorProperties {
                  colorhex
                  colorname
                  colorparameter
                }
              }
            }
            avionautShopUrl
            thumbnailphotos {
              productcolorversion {
                ... on WpColor {
                  colorProperties {
                    colorhex
                    colorname
                    colorparameter
                  }
                }
              }
              photo {
                id
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, quality: 100)
                  }
                }
              }
            }
            tags
            producttags {
              tagname
            }
          }
          slug
        }
      }
    }
    allWpTopBar(filter: {locale: {locale: {eq: $locale}}}) {
      edges {
        node {
          id
          locale {
            id
            locale
          }
          topBarData {
            buttoncta
            buttontext
            istopbarvisible
            promotext
            promotextmobile
          }
        }
      }
    }
  }

`;

export default ProductsTemplate;
