import React, { Dispatch, FC, SetStateAction } from 'react';

import Filter from '@assets/svg/filter.svg';
import Close from '@assets/svg/close.svg';

import s from './FilterButton.module.scss';

interface IFilterButton {
  visible: boolean;
  handleClick: Dispatch<SetStateAction<boolean>>;
  text: string;
}

export const FilterButton: FC<IFilterButton> = ({ visible, handleClick, text }) => {

  function renderCloseButton() { 
    return (
      <div className={s.filterButton__close} onClick={() => handleClick(!visible)}>
        <div className={s.filterButton__image}>
          <Close className={s.filterButton__imageSvg}/>
        </div>
      </div>
    );
  }

  function renderFilterButton() {
    return (
      <div className={s.filterButton} onClick={() => handleClick(!visible)}>
        <div className={s.filterButton__text}>
          <p>{text}</p>
        </div>
        <div className={s.filterButton__icon}>
          <div className={s.filterButton__image}>
            <Filter />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {visible ? renderCloseButton() : renderFilterButton()}
    </>
  );
};
