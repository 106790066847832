import React, { FC, useRef, useState } from 'react';

import { useIntl } from 'gatsby-plugin-intl';

import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

import { useWindowSize } from '../../hooks/use-window-size';
import { useOutsideClick } from '../../hooks/use-click-outside';

import { CustomSelect } from '../custom-select/CustomSelect.component';
import { Container } from '../container/Container.component';
import { Header } from '../header/Header.component';
import { FilterButton } from '../filter-button/FilterButton.component';
import { Loader } from '../loader/Loader.component';

import { FilterData } from '../../types';
import { BREAKPOINT, isMobileResolution } from '@helpers/layout.helper';

import s from './Configurator.module.scss';


interface IConfigurator {
  filterProducts(age: number | null, weight: number | null, growth: number | null): void;

  preselectedData: FilterData | null;
  isHeaderHide?: boolean;
  isTopBarVisible: boolean
}

export const Configurator: FC<IConfigurator> = ({ isHeaderHide, filterProducts, preselectedData, isTopBarVisible }) => {
  const intl = useIntl();

  const rangeAge: string[] = [intl.formatMessage({ id: 'configurator__ageValueFirst' }), intl.formatMessage({ id: 'configurator__ageValueSecond' }), intl.formatMessage({ id: 'configurator__ageValueThree' })];

  const rangeWeight: string[] = [intl.formatMessage({ id: 'configurator__weightValueFirst' }), intl.formatMessage({ id: 'configurator__weightValueSecond' }), intl.formatMessage({ id: 'configurator__weightValueThree' }), intl.formatMessage({ id: 'configurator__weightValueFour' })];

  const rangeGrowth: string[] = [intl.formatMessage({ id: 'configurator__heightValueFirst' }), intl.formatMessage({ id: 'configurator__heightValueSecond' }), intl.formatMessage({ id: 'configurator__heightValueThree' }), intl.formatMessage({ id: 'configurator__heightValueFour' })];

  const [selectedAge, setAge] = useState<string | undefined>((preselectedData && preselectedData.age !== null) ? rangeAge[preselectedData.age] : undefined);
  const [selectedWeight, setWeight] = useState<string | undefined>((preselectedData && preselectedData.weight !== null) ? rangeWeight[preselectedData.weight] : undefined);
  const [selectedGrowth, setGrowth] = useState<string | undefined>((preselectedData && preselectedData.growth !== null) ? rangeGrowth[preselectedData.growth] : undefined);
  const [displayDropdownMenuAge, setDisplayDropdownAge] = useState<boolean>(false);
  const [displayDropdownMenuWeight, setDisplayDropdownWeight] = useState<boolean>(false);
  const [displayDropdownMenuGrowth, setDisplayDropdownGrowth] = useState<boolean>(false);
  const [displayMobileFilter, setDisplayMobileFilter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const refDropdown = useRef<HTMLDivElement>(null);

  const windowSize = useWindowSize();
  const sendDataToGTM = useGTMDispatch()

  const isMinTablet = isMobileResolution(windowSize, BREAKPOINT.MIN_TABLET);
  
  const handleSetAge = (value: string) => {
    setIsLoading(true);
    setDisplayMobileFilter(false);
    setAge(value);
    closeAllDropdowns();
    sendDataToGTM({
      'event': 'configurator-prod-list',
      'event_category': 'dropdown-age click',
      'event_label': `${typeof value === 'undefined' ? 'reset' : value}`,
      'event_language': intl.locale,
    })
    setTimeout(() => convertValuesAndFilterProducts(value,selectedWeight,selectedGrowth), 1000);
  }

  const handleSetWeight = (value: string) => {
    setIsLoading(true);
    setDisplayMobileFilter(false);
    setWeight(value);
    closeAllDropdowns();
    sendDataToGTM({
      'event': 'configurator-prod-list',
      'event_category': 'dropdown-weight click',
      'event_label': `${typeof value === 'undefined' ? 'reset' : value}`,
      'event_language': intl.locale,
    })
    setTimeout(() => convertValuesAndFilterProducts(selectedAge,value,selectedGrowth), 1000);
  }

  const handleSetGrowth = (value: string) => {
    setIsLoading(true);
    setDisplayMobileFilter(false);
    setGrowth(value);
    closeAllDropdowns();
    sendDataToGTM({
      'event': 'configurator-prod-list',
      'event_category': 'dropdown-growth click',
      'event_label': `${typeof value === 'undefined' ? 'reset' : value}`,
      'event_language': intl.locale,
    })
    setTimeout(() => convertValuesAndFilterProducts(selectedAge,selectedWeight,value), 1000);
  }


  function displayDropdownAgeChange(value: boolean) {
    setDisplayDropdownAge(value);

    if (value) {
      setDisplayDropdownWeight(false);
      setDisplayDropdownGrowth(false);
    }
  }

  function displayDropdownWeightChange(value: boolean) {
    setDisplayDropdownWeight(value);

    if (value) {
      setDisplayDropdownAge(false);
      setDisplayDropdownGrowth(false);
    }
  }

  function displayDropdownGrowthChange(value: boolean) {
    setDisplayDropdownGrowth(value);
    
    if (value) {
      setDisplayDropdownWeight(false);
      setDisplayDropdownAge(false);
    }
  }

  function closeAllDropdowns() {
    if (displayDropdownMenuAge || displayDropdownMenuWeight || displayDropdownMenuGrowth) {
      setDisplayDropdownWeight(false);
      setDisplayDropdownAge(false);
      setDisplayDropdownGrowth(false);
    }
  }

  const convertValuesAndFilterProducts = (readiedAge: string | undefined, readiedWeight: string | undefined, readiedGrowth: string | undefined) => {
    let age = null;
    let weight = null;
    let growth = null;

    if (typeof readiedAge !== 'undefined') {
      age = rangeAge.indexOf(readiedAge);
    }

    if (typeof readiedWeight !== 'undefined') {
      weight = rangeWeight.indexOf(readiedWeight);
    }

    if (typeof readiedGrowth !== 'undefined') {
      growth = rangeGrowth.indexOf(readiedGrowth);
    }

    filterProducts(age, weight, growth);

    if (isMinTablet) {
      setDisplayMobileFilter(false);
    }

    setIsLoading(false)
  };

  useOutsideClick(refDropdown, () => {
      setDisplayDropdownAge(false);
      setDisplayDropdownWeight(false);
      setDisplayDropdownGrowth(false);
  });

  return (
    <section className={`${s.configurator} ${isHeaderHide ? s.noMargin : ''} ${isTopBarVisible ? s.lowerConfigurator: ''}`} onClick={() => closeAllDropdowns()}>
      <Container>
        <Loader isLoading={isLoading}/>
        {!isHeaderHide && <Header isMainHeader children={intl.formatMessage({ id: 'configurator__header' })} />}
        <div className={s.configurator__filterButton}>
          <FilterButton visible={displayMobileFilter} handleClick={setDisplayMobileFilter}
                        text={intl.formatMessage({ id: 'configurator__buttonText' })} />
        </div>
        <div
          className={`${s.configurator__content} ${isHeaderHide ? s.products : ''} ${!isHeaderHide && displayMobileFilter ? s.visibleProduct : ''} ${displayMobileFilter ? s.visible : ''}`}>
          <div className={s.configurator__panel} ref={refDropdown}>
            <div className={s.configurator__age}>
              <CustomSelect
                text={intl.formatMessage({ id: 'configurator__ageLabel' })}
                placeholder={intl.formatMessage({ id: 'configurator__agePlaceholder' })}
                range={rangeAge}
                visible={displayDropdownMenuAge}
                handleClick={displayDropdownAgeChange}
                ageSelector
                value={selectedAge}
                handleSelect={handleSetAge}
              />
            </div>
            <div className={s.configurator__weight}>
              <CustomSelect
                text={intl.formatMessage({ id: 'configurator__weightLabel' })}
                placeholder={intl.formatMessage({ id: 'configurator__weightPlaceholder' })}
                range={rangeWeight}
                visible={displayDropdownMenuWeight}
                handleClick={displayDropdownWeightChange}
                value={selectedWeight}
                handleSelect={handleSetWeight}
              />
            </div>
            <div className={s.configurator__growth}>
              <CustomSelect
                text={intl.formatMessage({ id: 'configurator__heightLabel' })}
                placeholder={intl.formatMessage({ id: 'configurator__heightPlaceholder' })}
                range={rangeGrowth}
                visible={displayDropdownMenuGrowth}
                handleClick={displayDropdownGrowthChange}
                value={selectedGrowth}
                handleSelect={handleSetGrowth}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
